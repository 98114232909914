.App {
  text-align: center;
  color: #74605A;
  background-color: white;
}

.link-container {
  border-bottom: solid #74605A;
  padding-bottom: 1rem;
}

.link {
  color: #74605A;
  width: 100%;
  text-decoration: none;
  display: inline;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 1.75em;
  cursor:crosshair;
  transition-duration: 1s;
  font-family: 'Playfair', serif;
}

.link:hover {
  color:#C3BBB2;
  cursor: crosshair;
}

.section-header {
  font-size: 2.5em;
  font-family: 'Playfair', serif;
}

/* Hero Section */
.hero {
  min-height: 47rem;
  max-width: 100%;
}

.hero-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 12rem;
}

.hero-image-container {
  display: flex;
  height: 47rem;
  width: 40rem;
  background-image: url('./assets/heroImage.jpg');
  background-size: cover;
}

.hero-text {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.header-name {
  font-size: 6.5em;
  font-family: 'Playfair', serif;
}

.sub-header {
  font-size: 2em;
  font-weight: bolder;
}

.skills-list {
  font-weight: 300;
  font-size: 1.4em;
}


/* About Section */
.about {
  min-height: 47rem;
  background-color: #ECEADA;
  padding-top: 1rem;
}

.about-main {
  display: flex;
  flex-direction: row;
}

.about-header {
  font-size: 3em;
}

.about-text {
  font-size: 1.5em;
  padding: 1rem 6rem 0rem 6rem;
  font-family: 'Roboto', sans-serif;
}

.about-image {
  height: 35rem;
}
.about-link {
  transition-duration: 1s;
}

.about-link:link { 
  text-decoration: none; 
  color: #74605A;

} 
.about-link:visited { 
  text-decoration: none; 
  color: #74605A;

} 
.about-link:hover { 
  text-decoration: none; 
  color:#C3BBB2;
  cursor: crosshair;

} 
.about-link:active { 
  text-decoration: none; 
  color: #74605A;

}

/* Projects Section */
.projects {
  height: 47rem;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  justify-items: center;
  gap: 20px; 
  margin-bottom: -30rem;
}

#project1 {
  width: 22rem;
}

#project2 {
  width: 20rem;
  height: 20rem;
}

#project3 {
  width: 20rem;
}

.project-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5rem;
  padding-left: 3rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.3em;
  padding-top: 30rem;
}

.project-link {
  transition-duration: 1s;
  font-size: 1.2em;
}

.project-link:link { 
  text-decoration: none; 
  color: #74605A;

} 
.project-link:visited { 
  text-decoration: none; 
  color: #74605A;

} 
.project-link:hover { 
  text-decoration: none; 
  color:#C3BBB2;
  cursor: crosshair;

} 
.project-link:active { 
  text-decoration: none; 
  color: #74605A;

}

/* Contact Section */
.contact {
  min-height: 47rem;
  background-color: #ECEADA;
  padding-top: 1rem;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  padding-top: 10rem;
}

.contact-icon {
  font-size: 5.5em;
  transition-duration: 1s;
}

#linkedin:visited {
  color: #74605A;
}

#github:visited {
  color: #74605A;
}

#email {
  color: #74605A;
}

#email:visited {
  color: #74605A;
}

.contact-icon:hover {
  color:#C3BBB2;
  cursor: crosshair;
}

/* scroll to top button */
.top-btn-position {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

.top-btn-style {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #74605A;
  background-color: #fff;
  color: #74605A;
  cursor: pointer;
  transition-duration: 1s;
}

.top-btn-style:hover {
  animation: none;
  background-color: #74605A;
  color: white;
  border: 2px solid white;
}

